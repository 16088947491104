




















































































import Vue, { VueConstructor } from 'vue';
import draggable from 'vuedraggable';
import { ApolloError } from 'apollo-client';
import Spinner from '@/components/Spinner.vue';
import { ELEMENTS_HOTKEYWORDS_QUERY } from '../queries/query';
import { Table, TableColumn } from 'element-ui';
import {
  HotKeyword,
  HotKeywordParam,
  HotKeywordManagedType,
} from '../model/hotKeyword';
import { Select, Option } from 'element-ui';
import { HotKeywordService } from '../service/HotKeywordService';
import { apolloClient } from '@/apolloClient';

const service = new HotKeywordService(apolloClient);

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLElement } }>
).extend({
  name: 'SearchList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    Spinner,
    draggable,
  },
  data() {
    return {
      isAnyChanged: false,
      hotKeywords: [] as HotKeyword[],
      loading: 0,
      saveAllLoading: false,
      managedTypes: Object.keys(HotKeywordManagedType).map(type => ({
        text: type,
        value: type,
      })),
    };
  },

  methods: {
    // isAnyChanging(): boolean {
    //   return this.hotKeywords.some(hotKeyword => hotKeyword.isChanging == true);
    // },
    sortSeqNo(): void {
      this.hotKeywords.forEach(
        (hotKeyword, idx) => (hotKeyword.seqNo = idx + 1)
      );
    },
    updatedManagedType(idx: number): void {
      this.hotKeywords[idx].text = '';
      this.hotKeywords[idx].exposureText = '';
    },
    addHotKeyword(): void {
      this.hotKeywords.push({
        id: '0',
        seqNo: this.hotKeywords.length + 1,
        text: '',
        exposureText: '',
        managedType: HotKeywordManagedType.ADMIN,
      });
    },
    hotKeywordToParam(param: HotKeyword): HotKeywordParam {
      const { id, seqNo, exposureText, managedType } = param;
      return {
        id: id === '0' ? undefined : id,
        seqNo: seqNo,
        text: exposureText,
        exposureText: exposureText,
        managedType: managedType,
      };
    },
    async saveHotKeywords(): Promise<void> {
      this.$modal.show(
        {
          title: '변경 하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        async () => {
          const inputHotKeyword = this.hotKeywords.map(this.hotKeywordToParam);
          try {
            if (inputHotKeyword.length > 10) {
              throw new Error('인기 검색어의 최대 갯수는 10개 입니다.');
            }
            this.saveAllLoading = true;
            await service.saveHotKeywords(inputHotKeyword);
            this.$apollo.queries.hotKeywords.refetch();
            this.showSuccessMessage('적용 되었습니다.');
            this.saveAllLoading = false;
          } catch (err) {
            console.error(err);
            this.showErrorMessage(err.message);
            this.saveAllLoading = false;
          }
        }
      );
    },
    showErrorMessage(message: string): void {
      this.$notify({
        duration: 5,
        title: '실패',
        message: message.replace('GraphQL error:', ''),
        type: 'warning',
      });
    },
    showSuccessMessage(message: string): void {
      this.$notify({
        duration: 5,
        title: '성공',
        message: message,
        type: 'success',
      });
    },
  },
  apollo: {
    hotKeywords: {
      query: ELEMENTS_HOTKEYWORDS_QUERY,
      variables(): { count?: number; all?: boolean } {
        return { all: true };
      },
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      update(data: { elements: { hotKeywords: HotKeyword[] } }): HotKeyword[] {
        return data.elements.hotKeywords;
      },
    },
  },
});
