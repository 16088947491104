import gql from 'graphql-tag';

export const SAVE_HOTKEYWORDS_MUTATION = gql`
  mutation ($params: [HotKeywordParam!]!) {
    saveHotKeywords(params: $params) {
      id
      exposureText
      text
      seqNo
      managedType
    }
  }
`;
