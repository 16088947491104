export enum HotKeywordManagedType {
  DATA = 'DATA',
  ADMIN = 'ADMIN',
}

export interface HotKeyword {
  id: string;
  exposureText: string;
  text: string;
  seqNo: number;
  managedType: HotKeywordManagedType;
}

export interface HotKeywordParam {
  id?: string;
  exposureText?: string;
  text?: string;
  seqNo: number;
  managedType: HotKeywordManagedType;
}
