import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { SAVE_HOTKEYWORDS_MUTATION } from '../queries/mutation';
import { HotKeywordParam, HotKeywordManagedType } from '../model/hotKeyword';

export class HotKeywordService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }
  async saveHotKeywords(params: HotKeywordParam[]): Promise<void> {
    this.validate(params);
    await this.apollo.mutate({
      mutation: SAVE_HOTKEYWORDS_MUTATION,
      variables: {
        params: params,
      },
    });
  }
  validate(params: HotKeywordParam[]): void {
    this.validateSeqNo(params);
    this.validateExposeTextAndText(params);
  }

  private validateExposeTextAndText(params: HotKeywordParam[]): void {
    params.forEach(({ text, exposureText, managedType }) => {
      if (
        managedType === HotKeywordManagedType.ADMIN &&
        (text === '' || exposureText === '')
      ) {
        throw new Error('ADMIN의 인기검색어를 입력해주세요.');
      }
      if (managedType === HotKeywordManagedType.DATA && text !== exposureText) {
        throw new Error('DATA의 인기검색어와 노출텍스트가 상이합니다.');
      }
    });
  }

  private validateSeqNo(params: HotKeywordParam[]): void {
    const seqNos = params.map(({ seqNo }) => {
      return seqNo;
    });
    seqNos.sort((a, b) => a - b);
    // check 1 ~ params.length + 1
    for (const [idx, seqNo] of seqNos.entries()) {
      if (idx + 1 != seqNo) {
        throw new Error('seqNo는 1부터 N까지 여야합니다.');
      }
    }
  }
}
