import gql from 'graphql-tag';

export const ELEMENTS_HOTKEYWORDS_QUERY = gql`
  query ($count: Int, $all: Boolean) {
    elements {
      hotKeywords(count: $count, all: $all) {
        id
        exposureText
        text
        seqNo
        managedType
      }
    }
  }
`;
